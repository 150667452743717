<template>
    <div class="video-container">
      <video ref="videoRef" @ended="onVideoEnded" autoplay muted playsinline class="video">
        <source :src="require('@/assets/door.mp4')" type="video/mp4">
        Your browser does not support HTML5 video.
      </video>
      <div v-if="isVideoEnded" class="black-overlay"></div>
      <transition name="fade">
        <div v-if="showOverlayText" class="overlay-text">
          <a href='/code.html'> 🔓 Click Me. </a>
        </div>
      </transition>
    </div>
</template>
  
<script>
export default {
  name: 'OpenDoor',
  data() {
    return {
      isVideoEnded: false,
      showOverlayText: false,
    };
  },
  methods: {
    onVideoEnded() {
      this.isVideoEnded = true;
    },
    resetVideo() {
      this.$refs.videoRef.currentTime = 0;
      this.$refs.videoRef.play();
      this.isVideoEnded = false;
      this.showOverlayText = false;
    },
    stopVideo() {
      this.$refs.videoRef.pause();
      this.isVideoEnded = true;
      this.showOverlayText = false;
    },
  },
  mounted() {
    this.$refs.videoRef.addEventListener('playing', () => {
      setTimeout(() => {
        this.showOverlayText = true;
      }, 7000); // Delay of 7 seconds (7000 milliseconds)
    });
  },
};
</script>
  
<style scoped>
.video-container {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.video {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: 70vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.overlay-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-family: monospace;
  align-items: center;
  color: #fff;
  font-size: 3em;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.black-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 20s;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
  .fade-enter-to, .fade-leave-from {
    opacity: 1;
  }

  .overlay-text > a {
    text-decoration: none;
    color: white; /* Inherits the text color from its parent */
    cursor: text; /* Changes the cursor to a text cursor */
  }
  
</style>
