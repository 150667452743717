<template>
  <div id="app">
    <ul id="menu">
      <li data-menuanchor="firstPage" class="active"><a href="#firstPage">2020</a></li>
      <li data-menuanchor="secondPage"><a href="#secondPage">2021</a></li>
      <li data-menuanchor="thridPage"><a href="#thridPage">2022</a></li>
      <li data-menuanchor="fouthPage"><a href="#fouthPage">2023</a></li>
    </ul>
    <FullPage :options="options" ref="fullpage" @afterLoad="handleAfterLoad" @onLeave="handleOnLeave">
      <div class="section">
        <HomePage />
      </div>
      <div class="section">
        <AboutUs />
      </div>
      <div class="section">
        <MusicPlay />
      </div>
      <div class="section">
        <OpenDoor />
      </div>
    </FullPage>
  </div>
</template>

<script>
import HomePage from './components/HomePage.vue';
import AboutUs from './components/AboutUs.vue';
import OpenDoor from './components/DoorOpen.vue';
import MusicPlay from './components/MusicPlay.vue';
import { FullPage } from 'vue-fullpage.js';

export default {
  name: 'App',
  components: {
    HomePage,
    AboutUs,
    OpenDoor,
    FullPage,
    MusicPlay,
  },
  data() {
    return {
      options: {
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        scrollOverflow: true,
        scrollBar: false,
        menu: '#menu',
        showActiveTooltip: true,
        navigation: true,
        navigationPosition: 'right',
        navigationTooltips: ['firstPage', 'secondPage', 'thridPage','fouthPage'],
        anchors: ['firstPage', 'secondPage', 'thridPage', 'fouthPage'],
      },
    };
  },
  mounted() {
    // Make a GET request to your webhook URL
    fetch('https://3z8qmik5lj.execute-api.us-east-2.amazonaws.com/password?code=browsing_tricia_unframed')
      .then(() => {
        // Handle webhook success
        console.log('Webhook sent successfully!');
      })
      .catch(error => {
        // Handle webhook error
       console.error('Error sending webhook:', error);
    });
  },
};
</script>





<style>
body {
  background-color: black; /* replace #yourcolor with the color you want, for example #ff0000 for red */
}

    ul {
        list-style-type: none;
        padding: 0;
    }
    li {
        display: inline-block;
        margin: 0 10px;
    }
    #menu {
      position: fixed;
      top: 20px;
      right: 20px;
      z-index: 70;
      -webkit-font-smoothing: antialiased;
      -moz-font-smoothing: antialiased;
      letter-spacing: 1px;
      font-size: 1.1em;
  }
   #menu a{
    color: #fff;
    padding: 0 1.1em 1.1em 1.1em;
    text-decoration: none;
    font-family: arial,helvetica;
    position: relative
  }
  #menu li.active a:after {
    content: '';
    margin: 0 1.1em 0 1.1em;
    height: 2px;
    background: #fff;
    display: block;
    position: absolute;
    bottom: -6px;
    left: 0;
    right: 0;
    display: block
}

@media screen and (max-width: 900px), screen and (max-height: 500px) {
  #menu {
    text-align: center;
    left: 0 !important;
    right: 0 !important;
  }

  #menu a {
    font-size: 9px;
  }
}

@font-face{
  font-family: 'cubic';
  src: local("cubic"), url(./assets/font/Cubic_11_1.013_R.ttf) format("truetype");
}
</style>