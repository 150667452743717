<template>
    <div class="home">
      <transition name="fade">
    <div class="unframed" v-if="showText">
      
      U N F R A M E D
    
      <div v-if="showBorder">
        <div class="border top"></div>
        <div class="border right"></div>
        <div class="border bottom"></div>
        <div class="border left"></div>
      </div>
    </div>
  </transition>
    <br>
    <transition name="fade">
    <div class="text" v-if="showName">
      <a>L H C  |  T L F</a>
    </div>
  </transition>
</div>
  </template>
  
  <script>
  export default {
    name: 'UnframedText',
    data() {
      return {
        showText: false,
        showName: false,
        showBorder: false,
      };
    },
    mounted() {
      setTimeout(() => {
        this.showText = true;
      }, 0);
      setTimeout(() => {
        this.showName = true;
      }, 1000);
      setTimeout(() => {
        this.showBorder = true;
      }, 500);
    },
  };
  </script>

<style scoped>
.text{
  color:white;
  font-size: 1em;
}
.home {
  background-color: black;
  flex-direction: column;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overscroll-behavior: none;
  overflow-y: auto; /* allow scroll */
  -webkit-overflow-scrolling: touch; /* allow scroll */
}

.unframed {
  position: relative;
  color: white;
  font-size: 3em;
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto; /* Add this line to center the element horizontally */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.border {
  position: absolute;
  background: white;
  visibility: hidden;
}

.border.top {
  width: 0;
  height: 1px;
  top: 0;
  left: 0;
  animation: show 0.5s steps(1) forwards, drawLineHorizontally 1s linear 0.5s forwards;
}

.border.right {
  width: 1px;
  height: 0;
  top: 0;
  right: 0;
  animation: show 0.5s steps(1) 1s forwards, drawLineVertically 1s linear 1.5s forwards;
}

.border.bottom {
  width: 0;
  height: 1px;
  bottom: 0;
  right: 0;
  animation: show 0.5s steps(1) 2s forwards, drawLineHorizontally 1s linear 2.5s forwards;
}

.border.left {
  width: 1px;
  height: 0;
  bottom: 0;
  left: 0;
  animation: show 0.5s steps(1) 3s forwards, drawLineVertically2 1s linear 3.5s forwards;
}

@keyframes drawLineHorizontally {
  to {
    width: 100%;
  }
}

@keyframes drawLineVertically {
  to {
    height: 100%;
  }
}

@keyframes drawLineVertically2 {
  to {
    height: 100%;
  }
}

@keyframes show {
  to {
    visibility: visible;
  }
}

@media (max-width: 600px) {
  .unframed {
    margin-top: 120px;
    font-size: 2em;
    width: 300px;
    height: 300px;
  }


}
</style>

