<template>
      <UnframedText />
</template>

<script>
import UnframedText from './UnframedText.vue';

export default {
  name: 'HomePage',
  components: {
    UnframedText,
  },
};
</script>
