<template>
    <div class="video-container">
      <div class="video-wrapper">
        <iframe
          width="100%"
          :height="videoHeight"
          :src="videoSource"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'VideoMusic',
    data() {
      return {
        videoSource: 'https://www.youtube.com/embed/62PXvjuzYHM',
      };
    },
    computed: {
      videoHeight() {
        return Math.min(window.innerHeight, window.innerWidth * 0.5625); // Maintain 16:9 aspect ratio
      },
    },
  };
  </script>
  
  <style scoped>
  .video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .video-wrapper {
    position: relative;
    width: 100%;
    max-height: 100%;
  }
  
  </style>
  