<template>
  <div class="about">
    <UnframedText />
    <div id="typed-strings">
      <p>李曉津，多謝你陪我整左個Unframed出嚟，作為第一個首席支持者，呢個屬於你獨一無二既Unframed，Unframed既雛形。
        請發揮你既一切小宇宙，參與呢個小任務，你可以向下拉播放GameMusic 啦！Enjoy.
        <br>（好重要講三次，小心𠝹手，小心𠝹手，小心𠝹手。）<br>蔡凌輝</p>
    </div>
    <span id="typed"></span>
    <div class="background-image"></div>
  </div>
</template>

<script>
import Typed from 'typed.js';
import UnframedText from './UnframedText.vue';

export default {
  name: 'AboutUs',
  component:{
    UnframedText,
  },
  mounted() {
    const options = {
      stringsElement: '#typed-strings',
      typeSpeed: 200,
      backSpeed: 40,
      loop: false,
    };
    this.typed = new Typed('#typed', options);
  },
  unmounted() {
    this.typed.destroy();
  },
}
</script>

<style scoped>
.about {
  position: relative; /* 添加相对定位 */
  background-color: black;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overscroll-behavior: none;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.about .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/background.webp");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto;
  filter: blur(5px) brightness(100%);
  image-rendering: pixelated;
  z-index: 0;
}

@media (max-width: 599px) {
  /* 當螢幕寬度小於或等於599px時（通常為手機端） */
  .about .background-image {
    background-image: url("@/assets/background_m.webp");
  }
}

.about #typed, .about #typed-strings p {
  color: white;
  text-decoration: none;
  font-family: 'cubic';
  font-size: 15px;
  line-height: 3.5; /* 增加行距 */
  padding: 0 5%; /* 增加左右兩側的空間 */
  z-index: 1; /* 将文字置于背景图像上方 */
}

@media (min-width: 600px) {
  /* 當螢幕寬度大於600px時（通常為電腦端），限制每行的最大字元數為10 */
  .about #typed, .about #typed-strings p {
    max-width: 70ch;
  }
}

@media (max-width: 599px) {
  /* 當螢幕寬度小於或等於599px時（通常為手機端），限制每行的最大字元數為8 */
  .about #typed, .about #typed-strings p {
    max-width: 30ch;
  }
}
</style>